import * as React from "react";
import { Routes, Route } from "react-router-dom";
import LicenseScanner from "./view/licenseScanner";
import RouterScanner from "./view/RouterScanner";
import VehicleScanner from "./view/vehicleScanner";



export default function Main() {
  return (
    <Routes>
      <Route path=":db/:id/router" element={<RouterScanner />} />
      <Route path=":db/:id/vehicle" element={<VehicleScanner />} />
      <Route path=":db/:id/license" element={<LicenseScanner />} />
    </Routes>
  );
}