import Button from "@mui/material/Button";
import React from "react";
import styled from "styled-components";
import LoadingState from "./loadingState";

const StyledContainer = styled.div`&&{
    display: flex;
    flex-direction: row;
    justify-content: center;
}`

const CameraField = styled.div`&&{
    display: flex;
    flex-direction: column;
    justify-content: center;
}`

const StyledDiv = styled.div`&&{
    width: 390px;
}`

const ButtonContainer = styled.div`&&{
    width: 390px;
    margin-top: 20px;
}`;

const StyledButton = styled(Button) <{ primary?: boolean }>`&&{
    padding: 0.2em 1em 0.2em 0.7em;
    background-color: ${({ primary }) => primary ? `#6BBD57` : `#E9E9E9`};
    color: ${({ primary }) => primary ? `#white` : `black`};
    box-shadow: none;
    text-transform: none;
}`

interface CameraContainerProps {
    qrcodeRegionId: string;
    loadCamera: boolean;
    onCancel: (event: React.SyntheticEvent) => void;
}

const CameraContainer: React.FunctionComponent<CameraContainerProps> = ({
    qrcodeRegionId,
    loadCamera,
    onCancel
}) => {
    return (
        <StyledContainer>
            <CameraField>
                <StyledDiv id={qrcodeRegionId} />
                {!loadCamera ? (<ButtonContainer>
                    <StyledButton
                        variant="contained"
                        fullWidth
                        onClick={onCancel}>
                        Cancel
                    </StyledButton>
                </ButtonContainer>) : (<LoadingState />)}
            </CameraField>
        </StyledContainer>
    )
}

export default CameraContainer;