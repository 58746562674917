
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode"
import React from 'react';
import queryString from 'query-string';
import { isEmpty, get } from 'lodash';

import { SubmitHandler } from "react-hook-form";
import axios from 'axios';
import CameraContainer from "../component/cameraContainer";
import VehicleForm, { IFormInput } from "../component/forms/vehicleForm";
import { API, QB_APP_TOKEN, QB_HOSTNAME } from '../utils/enums';
import { formatDate } from "../utils/general";

const qrcodeRegionId = "html5qr-code-full-region-vehicle";
const headers = {
    "Content-Type": "application/json",
};


const search:any = queryString.parse(window.location.search);
const redirect: any = get(search, 'redirect', undefined);
const tid:any =  get(search, 'tid', undefined);
const api:any = get(search,'api',undefined);
const fid_to_append:any = get(search, 'fid_to_append', undefined);
const readOnly = get(search, 'read_only', undefined);

interface StateProps {
    cameraId: string;
    qrCode: string;
    defaultValues: IFormInput;
    isLoading: boolean;
    loadCamera: boolean;
}
class VehicleScanner extends React.Component<any, StateProps>{
    html5QrCode: Html5Qrcode | undefined;
    state: StateProps = {
        cameraId: '',
        qrCode: '',
        isLoading: false,
        loadCamera: true,
        defaultValues: {
            id: undefined,
            vin: '',
            numberPlate: '',
            engineNumber: '',
            make: '',
            model: '',
            color: '',
            expiryDate: undefined,
            year: undefined
        }
    }

    public componentDidMount = async () => {
        try {
            this.html5QrCode = new Html5Qrcode(
                qrcodeRegionId,
                {
                    formatsToSupport: [Html5QrcodeSupportedFormats.PDF_417],
                    verbose: false
                });
            const devices = await Html5Qrcode.getCameras();
            if (devices && devices.length) {
                this.onStart(devices[0].id);
                this.setState({
                    cameraId: devices[0].id
                });
            }

        } catch (error) {
            console.log(error)
        }

    }

    public onStart = (cameraId: string) => {
        const settings = { facingMode: { exact: "environment"} };
        // const settings = { deviceId: { exact: cameraId } };
        const config = { fps: 10, qrbox: { width: 300, height: 150 } };
        this.html5QrCode!.start(
            settings,
            config,
            this.qrCodeSuccessCallback,
            this.qrCodeErrorCallback).catch((err) => {
                console.log(err)
            });
        this.setState({ cameraId });
        this.setState({ loadCamera: false });
    }

    public qrCodeSuccessCallback = async (decodedText: string, decodedResult: any) => {
        /* handle success */
        try {
            if (!isEmpty(decodedText) && this.state.qrCode !== decodedText) {
                this.setState({ qrCode: decodedText, loadCamera: true });
           
                // get data by qr code
                const url =  api ? `${api}/api/nps-lic` :`${API}/nps-lic`;
                const result = decodedText.split("%");
                const vin = result[result.length - 4];
                const {data} = await axios({
                    url,
                    method: 'POST',
                    headers,
                    data: {
                        scan: decodedText,
                        vin,
                    },
                });

                this.setState({
                    loadCamera: false,
                    defaultValues: {
                        id: undefined,
                        vin: get(data,'results.VIN',''),
                        numberPlate: get(data,'results.scan_plate',''),
                        engineNumber: get(data,'results.scan_eng',''),
                        make: get(data,'results.scan_manuf',''),
                        model: get(data,'results.scan_model',''),
                        color: get(data,'results.scan_colour',''),
                        expiryDate: formatDate(get(data,'results.scan_date', '')),
                        year: get(data,'results.year',undefined)
                    }
                });
                this.onStop();

            }
        } catch (error) {

            if (!isEmpty(decodedText)) {
                this.setState({
                    cameraId: '',
                    defaultValues: {
                        ...this.state.defaultValues,
                        // serialNumber: decodedText,
                    }
                });

                this.onStop();
            } else {
                if (redirect) {
                    window.location.href = redirect;
                }
            }
            console.error(error);
        }

    };

    public qrCodeErrorCallback = async(err: any) => {}

    public onStop = () => {
        try {
            this.setState({
                cameraId: '',
            })
            this.html5QrCode!.stop();
            this.html5QrCode!.clear();
        } catch (error) {
            console.log(error)
        }


    }

    public onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const {
                numberPlate,
                vin,
                make,
                model,
                expiryDate,
                color,
                year,
                engineNumber,
                id
            } = data;

            this.setState({ isLoading: true });
            const updatePayload = `
            <qdbapi>
                <apptoken>${QB_APP_TOKEN}</apptoken>
                ${id ? `<rid>${id}</rid>` : ``}
                <field name="number_plate">${numberPlate}</field>
                <field name="vin">${vin}</field>
                <field name="make">${make}</field>
                <field name="model">${model}</field>
                <field name="expiry_date">${expiryDate}</field>
                <field name="color">${color}</field>
                <field name="year">${year}</field>
                <field name="engine_number">${engineNumber}</field>
            </qdbapi>
            `
            const xmlUrl = `${QB_HOSTNAME}/db/${tid}`
            const response = await axios({
                url: xmlUrl,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/xml',
                    "QUICKBASE-ACTION": id ? "API_EditRecord" : "API_AddRecord"
                },
                data: updatePayload
            });
    
            this.setState({ isLoading: false });
            if (redirect) {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(response.data,"text/xml");
                const rid = xmlDoc.getElementsByTagName("rid")[0].childNodes[0].nodeValue;
                if(!isEmpty(fid_to_append) && rid){
                    search.redirect = undefined;
                    search.api = undefined;
                    search[fid_to_append] = rid;
                    const stringified = queryString.stringify(search);
                    window.location.href = `${redirect}&${stringified}`;
                }else{
                    window.location.href = redirect
                }
               
            }

        } catch (error) {
            this.setState({ isLoading: false });
            if (redirect) {
                window.location.href = redirect;
            }
        }

    };

    public onCancel = (e: React.SyntheticEvent) => {
        this.onStop();
        if (redirect) {
            window.location.href = redirect;
        }

    }

    public render(): React.ReactNode {
        if (isEmpty(this.state.defaultValues.vin)) {
            return (
                <CameraContainer
                    loadCamera={this.state.loadCamera}
                    qrcodeRegionId={qrcodeRegionId}
                    onCancel={this.onCancel} />
            )

        } else {
            return (
                <VehicleForm
                    isLOading={this.state.isLoading}
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    defaultValues={this.state.defaultValues} />
            )
        }
    }
};

export default VehicleScanner;
