import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
const StyledContainer = styled.div`&&{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
}`

export default function LoadingState() {
  return (
    <StyledContainer>
      <CircularProgress size={100}/>
    </StyledContainer>
  );
}