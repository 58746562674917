import * as React from 'react';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { ButtonContainer, FormControl, StyledButton, StyledForm, StyledLabel, StyledTextField } from '../ui/formControl';

interface RouterFormProps {
    onSubmit: (e: any) => void;
    onCancel: (e:React.SyntheticEvent) => void;
    isLOading: boolean;
    defaultValues: IFormInput;
    readOnly?:boolean;
}

export interface IFormInput {
    id?: string;
    serialNumber: string;
    ssid: string;
    macAddress: string;
    status: { label: string; value: string };
}

const RouterForm: React.FunctionComponent<RouterFormProps> = ({
    defaultValues,
    onSubmit,
    onCancel,
    isLOading,
    readOnly
}) => {
    const { control, handleSubmit } = useForm<IFormInput>({
        defaultValues
    });

    return (
        <StyledForm
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormControl>
                <StyledLabel>Serial number</StyledLabel>
                <Controller
                    name='serialNumber'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} disabled={readOnly} />}
                />

            </FormControl>
            <FormControl>
                <StyledLabel>SSID</StyledLabel>
                <Controller
                    name='ssid'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} disabled={readOnly} />}
                />
            </FormControl>
            <FormControl>
                <StyledLabel>MAC Address</StyledLabel>
                <Controller
                    name='macAddress'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field}  disabled={readOnly}/>}
                />
            </FormControl>
            <FormControl>
                <StyledLabel>Status</StyledLabel>
                <Controller
                    name="status"
                    control={control}
                    render={({ field }) => <Select
                        {...field}
                        styles={{
                            control:(rest)=>({
                                ...rest,
                                width: 193,
                                height: 32,
                                minHeight: 35
                            })
                        }}
                        options={[
                            { value: "Ready for Installation", label: "Ready for Installation" },
                            { value: "Commissioned", label: "Commissioned" },
                            { value: "Enroute to site", label: "Enroute to site" },
                            { value: "Defective", label: "Defective" },
                        ]}
                        isDisabled={readOnly}
                        
                    />}
                />
            </FormControl>
            <ButtonContainer>
                <StyledButton 
                type="submit" 
                disabled={isLOading}
                primary 
                variant="contained">{isLOading ? "Loading....": "Save & Close"}</StyledButton>
                <StyledButton variant="contained" onClick={onCancel}>Cancel</StyledButton>
            </ButtonContainer>
        </StyledForm>
    )
}

export default RouterForm;