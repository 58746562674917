import * as React from 'react';
import { useForm, Controller } from "react-hook-form";
import { ButtonContainer, ControllerContainer, ErrorMessage, FormControl, StyledButton, StyledForm, StyledLabel, StyledTextField } from '../ui/formControl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
    vin: yup.string().required("VIN is required").length(17,"Vin must be exactly 17 characters"),
    engineNumber: yup.string().required("engine number is required"),
    make: yup.string().required('make is required'),
    model: yup.string().required(),
    color: yup.string().required(),
    expiryDate: yup.string().required("disk expiry date is required")
  }).required();
interface VehicleFormProps {
    onSubmit: (e: any) => void;
    onCancel: (e:React.SyntheticEvent) => void;
    isLOading: boolean;
    defaultValues: IFormInput;
}

export interface IFormInput {
    id?: string;
    vin: string;
    numberPlate: string;
    engineNumber: string;
    make: string;
    model: string;
    color: string;
    year?: number;
    expiryDate?: Date | string;
}

const VehicleForm: React.FunctionComponent<VehicleFormProps> = ({
    defaultValues,
    onSubmit,
    onCancel,
    isLOading
}) => {
    const { control, handleSubmit, formState: { errors } } = useForm<IFormInput>({
        defaultValues,
        resolver: yupResolver(schema)
    });

    return (
        <StyledForm
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormControl>
                <StyledLabel>VIN</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='vin'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.vin?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Number plate</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='numberPlate'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.numberPlate?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Engine number</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='engineNumber'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.engineNumber?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Make</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='make'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.make?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Model</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='model'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.model?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Color</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='color'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.color?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Expiry date</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='expiryDate'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} type="date" />}
                />
                <ErrorMessage>{errors.expiryDate?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Year</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='year'
                    control={control}
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.year?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <ButtonContainer>
                <StyledButton 
                type="submit" 
                disabled={isLOading}
                primary 
                variant="contained">{isLOading ? "Loading....": "Save & Close"}</StyledButton>
                <StyledButton variant="contained" onClick={onCancel}>Cancel</StyledButton>
            </ButtonContainer>
        </StyledForm>
    )
}

export default VehicleForm;