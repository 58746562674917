import * as React from 'react';
import { useForm, Controller } from "react-hook-form";
import { ButtonContainer, ControllerContainer, ErrorMessage, FormControl, StyledButton, StyledForm, StyledLabel, StyledTextField } from '../ui/formControl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
    licenseNumber: yup.string().required("license number is required"),
    idNumber: yup.string().required("id number is required").length(13),
    surname: yup.string().required(),
    citizen: yup.string().required(),
    name: yup.string().required(),
    initial: yup.string().required("initials are required"),
    issued: yup.string().required("date issued is required"),
    validFrom: yup.string().required("date is required"),
    validTo: yup.string().required("date is required"),
  }).required();

interface LicenseFormProps {
    onSubmit: (e: any) => void;
    onCancel: (e:React.SyntheticEvent) => void;
    isLOading: boolean;
    defaultValues: DriverLicenseFormProps;
}

export interface DriverLicenseFormProps {
    id?: string;
    name: string;
    citizen: string;
    initial: string;
    surname: string;
    dob: string;
    idNumber: string;
    issuedBy: string;
    licenseNumber: string;
    issued: string;
    validFrom: string;
    validTo: string;
}

const LicenseForm: React.FunctionComponent<LicenseFormProps> = ({
    defaultValues,
    onSubmit,
    onCancel,
    isLOading
}) => {
    const { control, handleSubmit, formState: { errors } } = useForm<DriverLicenseFormProps>({
        defaultValues,
        resolver: yupResolver(schema)
    });

    return (
        <StyledForm
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormControl>
                <StyledLabel>Name</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='name'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.name?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Surname</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='surname'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.surname?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Initials</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='initial'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.initial?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>ID No.</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='idNumber'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.idNumber?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Date of birth</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='dob'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField type={'date'} {...field} />}
                />
                <ErrorMessage>{errors.dob?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>License No.</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='licenseNumber'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.licenseNumber?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Issued</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='issued'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField type={'date'} {...field} />}
                />
                <ErrorMessage>{errors.issued?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Issued by</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='issuedBy'
                    control={control}
                    defaultValue=""
                    render={({ field }) => <StyledTextField {...field} />}
                />
                <ErrorMessage>{errors.issuedBy?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Valid From</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='validFrom'
                    control={control}
                    render={({ field }) => <StyledTextField type={'date'} {...field} />}
                />
                <ErrorMessage>{errors.validFrom?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <FormControl>
                <StyledLabel>Valid To</StyledLabel>
                <ControllerContainer>
                <Controller
                    name='validTo'
                    control={control}
                    render={({ field }) => <StyledTextField type={'date'} {...field} />}
                />
                <ErrorMessage>{errors.validFrom?.message}</ErrorMessage>
                </ControllerContainer>
            </FormControl>
            <ButtonContainer>
                <StyledButton 
                type="submit" 
                disabled={isLOading}
                primary 
                variant="contained">{isLOading ? "Loading....": "Save & Close"}</StyledButton>
                <StyledButton variant="contained" onClick={onCancel}>Cancel</StyledButton>
            </ButtonContainer>
        </StyledForm>
    )
}

export default LicenseForm;