
import { BarcodeScanner, BarcodeReader } from 'dynamsoft-javascript-barcode';
import React from 'react';

BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNDEyMDIzLVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNDEyMDIzIiwiY2hlY2tDb2RlIjoxNTM3NTgyMTMzfQ==';
BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.0.2/dist/";

interface BarcodeScannerComponentProps {
    getLicense: (hex: string) => Promise<any>;
}

interface StateProps {
    bShowScanner: boolean;
    error: string;
}

class BarcodeScannerComponent extends React.Component<BarcodeScannerComponentProps, StateProps> {
    pScanner: any;
    elRef: React.RefObject<any>;
    constructor(props: any) {
        super(props);
        this.elRef = React.createRef();
    }
    state: StateProps = {
        bShowScanner: false,
        error: '',

    }


    async componentDidMount() {
        try {
            if (this.pScanner == null) {
                this.pScanner = BarcodeScanner.createInstance();
                const scanner = await this.pScanner;

                const cameras = await scanner.getAllCameras();
                if (cameras && cameras.length) {
                    await scanner.setCurrentCamera(cameras[0]);
                    this.elRef.current.appendChild(scanner.getUIElement());
                    this.elRef.current.style.width = "100%";
                    this.elRef.current.style.height = "100%";
                    await scanner.show();
                    scanner.onFrameRead = async (results: any) => {
                        for (let result of results) {
                            const hex = this.toHexString(result.barcodeBytes);
                            await this.props.getLicense(hex)
                        }
                    };
                } else {
                    this.setState({
                        error: 'No camera'
                    });
                }

            }

            this.setState({
                error: 'No pScanner'
            });
            console.log('No pScanner')
        } catch (ex: any) {
            this.setState({
                error: JSON.stringify(ex)
            });
            console.log(JSON.stringify(ex))
        }
    }

    public showScanner = () => {
        this.setState({
            bShowScanner: true
        });
    }

    public toHexString = (byteArray: Uint8Array) => {
        return Array.from(byteArray, function (byte) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join('')
    }


    public shouldComponentUpdate() {
        // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
        return false;
    }
    public render(): React.ReactNode {
        console.log(this.state.error)
        return (
            <>
                <p>{this.state.error}</p>
                <div ref={this.elRef}>
                </div>
            </>
        );
    }
}

export default BarcodeScannerComponent;