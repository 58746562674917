
import { has } from 'lodash'
import moment from 'moment';
interface FieldsProps {
    id: number;
    label: string;
    type: string;
}

interface DataProps {
    [key: number]: { value: string }
}


export const getFields = (
    fields: FieldsProps[],
    data: DataProps,
    fieldsToReturn: string[]
) => {
    let mappedValues: { [key: string]: any } = {}
    for (let i = 0; i < fieldsToReturn.length; i++) {
        const field = fieldsToReturn[i];
        for (let x = 0; x < fields.length; x++) {
            if (fields[x] && fields[x].label === field) {
                const id = fields[x] && fields[x].id
                if (has(data, id)) {
                    mappedValues[field] = data[id].value || ''
                }

            }
        }
    }

    return mappedValues;
}

export const formatDate = (strDate: string)=>{
    if(strDate){
        return moment(strDate,["MM-DD-YYYY", "YYYY-MM-DD"]).format('YYYY-MM-DD');
    }
    return strDate;
}

const splitByColon = (colonStr?:string)=>{
    if(colonStr){
        return colonStr.split(":").length === 2 ? colonStr.split(":")[1] : colonStr.toString();
    }
    
}

export const decodeRouter = (decodedText: string)=>{
    const splitedString = decodedText.split(";");
    
    const serialNumber = splitedString.find((word:string)=>word.includes("SN:"));
    const macAddress = splitedString.find((word:string)=>word.includes("M:"));
    const imei = splitedString.find((word:string)=>word.includes("I:"));
    const ssid = splitedString.find((word:string)=>word.includes("S:"));

   return {
    serialNumber: splitByColon(serialNumber),
    macAddress: splitByColon(macAddress),
    imei: splitByColon(imei),
    ssid: splitByColon(ssid)
   }
    
}