
import React from 'react';
import queryString from 'query-string';
import { get, isEmpty } from 'lodash';

import { BarcodeScanner } from 'dynamsoft-javascript-barcode';
import BarcodeScannerComponent from "../component/barcodeScannerComponent";
import { API, QB_APP_TOKEN, QB_HOSTNAME } from "../utils/enums";
import styled from 'styled-components';
import axios from 'axios';

import LicenseForm, { DriverLicenseFormProps } from '../component/forms/licenseForm';
import { SubmitHandler } from 'react-hook-form';
import { formatDate } from '../utils/general';

const StyledDiv = styled.div`&&{
    margin: 2vmin auto;
    text-align: center;
    font-size: medium;
    height: 90vh;
}`

const Results = styled.div`&&{
    display: block;
    margin: 0 auto;
    padding: 0.4rem 0.8rem;
    color: inherit;
    // width: 80vw;
    border: none;
    font-size: 1rem;
    border-radius: 0.2rem;
}`

const search = queryString.parse(window.location.search);
const redirect: any = get(search, 'redirect', undefined);
const tid: any = get(search, 'tid', undefined);
const api: any = get(search, 'api', undefined);

interface StateProps {
    resultValue: string;
    bShowScanner: boolean;
    libLoaded: boolean;
    defaultValues: DriverLicenseFormProps;
}

class LicenseScanner extends React.Component<any, StateProps>{
    pScanner: any;
    constructor(props: any) {
        super(props);
        this.pScanner = null;
    }

    state: StateProps = {
        resultValue: '',
        bShowScanner: false,
        libLoaded: false,
        defaultValues: {
            name: '',
            citizen: '',
            initial: '',
            surname: '',
            dob: '',
            idNumber: '',
            issuedBy: '',
            licenseNumber: '',
            issued: '',
            validFrom: '',
            validTo: ''
        },
    }
    async componentDidMount() {
        try {
            //Load the library on page load to speed things up.
            await BarcodeScanner.loadWasm();
            this.setState((state: StateProps) => {
                state.libLoaded = true;
                return state;
            }, () => {
                this.showScanner();
            });
        } catch (ex: any) {
            alert(ex.message);
            throw ex;
        }
    }
    public showScanner = () => {
        this.setState({
            bShowScanner: true
        });
    }

    public getLicense = async (hexBytes: string) => {
        try {
            const url = api ? `${api}/api/nps-dl` : `${API}/nps-dl`;
            const { data } = await axios({
                url,
                method: 'POST',
                headers:{
                    "Content-Type": "application/json",
                  },
                data: {
                    scan: hexBytes,
                },
            });

            const fields = {
                name: '',
                citizen: get(data,'results.Citizen',''),
                initial: get(data,'results.Initial',''),
                surname: get(data,'results.Surname',''),
                dob: formatDate(get(data,'results.DOB', '')),
                idNumber: get(data,'results.IDNo',''),
                issuedBy: get(data,'results.IssuedBy',''),
                licenseNumber: get(data,'results.LicNo',''),
                issued:  formatDate(get(data,'results.Issued', '')),
                validFrom: formatDate(get(data,'results.ValidFrom', '')),
                validTo: formatDate(get(data,'results.ValidTo', ''))
            }

            this.setState({
                defaultValues: fields
            });
        } catch (error:any) {
            alert(error.message);
            console.log(error);
        }
    }

    public onSubmit: SubmitHandler<DriverLicenseFormProps> = async (data) => {
        try {
            const {
                name,
                citizen,
                initial,
                surname,
                dob,
                idNumber,
                issuedBy,
                licenseNumber,
                issued,
                validFrom,
                validTo,
                id
            } = data;

            // this.setState({ isLoading: true });
            const updatePayload = `
            <qdbapi>
                <apptoken>${QB_APP_TOKEN}</apptoken>
                ${id ? `<rid>${id}</rid>` : ``}
                <field name="name">${name}</field>
                <field name="citizen">${citizen}</field>
                <field name="initial">${initial}</field>
                <field name="surname">${surname}</field>
                <field name="dob">${dob}</field>
                <field name="id_number">${idNumber}</field>
                <field name="license_number">${licenseNumber}</field>
                <field name="issued">${issued}</field>
                <field name="issued_by">${issuedBy}</field>
                <field name="valid_from">${validFrom}</field>
                <field name="valid_to">${validTo}</field>
            </qdbapi>
            `
            const xmlUrl = `${QB_HOSTNAME}/db/${tid}`;
            await axios({
                url: xmlUrl,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/xml',
                    "QUICKBASE-ACTION": id ? "API_EditRecord" : "API_AddRecord"
                },
                data: updatePayload
            });

            // this.setState({ isLoading: false });
            if (redirect) {
                window.location.href = redirect;
            }

        } catch (error) {
            // this.setState({ isLoading: false });
            if (redirect) {
                window.location.href = redirect;
            }
        }

    };

    public onCancel = (e: React.SyntheticEvent) => {
        if (redirect) {
            window.location.href = redirect;
        }

    }

    public render(): React.ReactNode {
        return (
            <>
                {isEmpty(this.state.defaultValues.licenseNumber) ? (<StyledDiv id="UIElement">
                    {!this.state.libLoaded ? (<span>Loading Library...</span>) : ""}
                    {this.state.bShowScanner ? (<BarcodeScannerComponent getLicense={this.getLicense}></BarcodeScannerComponent>) : ""}
                </StyledDiv>) : (
                    <Results>
                       <LicenseForm
                        onCancel={this.onCancel } 
                        onSubmit={this.onSubmit} 
                        defaultValues={this.state.defaultValues} 
                        isLOading={false}/>
                    </Results>
                )}

            </>

        );
    }
};

export default LicenseScanner;