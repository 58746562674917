import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export const FormControl = styled.div`&&{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-end;
}`
export const StyledLabel = styled.div`&&{
    display: flex;
    padding: 5px 11px;
}`;
export const StyledForm = styled.form`{
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    margin-left: auto;
    margin-right: auto;
    width: 324px;
    padding: 20px;
}`

export const StyledTextField = styled(TextField)`&&{
    .MuiOutlinedInput-input {
        width: 180px;
        height: 1.3em;
        padding: 6px 6px;
    }
}`

export const ButtonContainer = styled.div`&&{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}`

export const StyledButton = styled(Button)<{primary?: boolean}>`&&{
    padding: 0.2em 1em 0.2em 0.7em;
    margin-left: 10px;
    background-color: ${({primary})=>primary ? `#6BBD57`:`#E9E9E9`};
    color: ${({primary})=>primary ? `#white`:`black`};
    box-shadow: none;
    text-transform: none;
}`

export const ErrorMessage = styled.span`&&{
    color: red;
    font-size: 12px;
}`

export const ControllerContainer = styled.div`&&{
    display: flex;
    flex-direction: column;
    
}`