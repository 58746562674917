
import { Html5QrcodeSupportedFormats } from "html5-qrcode"
import React from 'react';
import queryString from 'query-string';
import { isEmpty, get } from 'lodash';
import RouterForm, { IFormInput } from "../component/forms/routerForm";
import { SubmitHandler } from "react-hook-form";
import axios from 'axios';
import { decodeRouter, getFields } from "../utils/general";
import CameraContainer from "../component/cameraContainer";
import { QB_APP_TOKEN, QB_HOSTNAME, QB_REALM, QB_USERTOKEN } from "../utils/enums";
import Html5Qrcode from '../utils/Html5QrCodeInverted';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const qrcodeRegionId = "html5qr-code-full-region";

const headers = {
    "QB-Realm-Hostname": QB_REALM,
    "Authorization": QB_USERTOKEN,
    "Content-Type": "application/json"
};


const search:any = queryString.parse(window.location.search);
const redirect: any = get(search, 'redirect', undefined);
const tid: any = get(search, 'tid', undefined);
// const bid_to_update = get(search, 'bid_to_update', undefined);
const fid_to_append = get(search, 'fid_to_append', undefined);
const readOnly = get(search, 'read_only', undefined);


interface StateProps {
    cameraId: string;
    qrCode?: string;
    defaultValues: IFormInput;
    isLoading: boolean;
    loadCamera: boolean;
}
class RouterScanner extends React.Component<any, StateProps>{
    html5QrCode: Html5Qrcode | undefined;
    state: StateProps = {
        cameraId: '',
        qrCode: '',
        isLoading: false,
        loadCamera: true,
        defaultValues: {
            id: undefined,
            macAddress: '',
            ssid: '',
            serialNumber: '',
            status: {
                value: '', label: ''
            }
        }
    }

    public componentDidMount = async () => {
        try {
            this.html5QrCode = new Html5Qrcode(qrcodeRegionId,
                {
                    formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
                    verbose: false
                });

            const devices = await Html5Qrcode.getCameras();
            if (devices && devices.length) {
                this.onStart(devices[0].id);
                this.setState({
                    cameraId: devices[0].id
                });
            }

        } catch (error) {
            console.log(error)
        }

    }

    public onStart = (cameraId: string) => {
        const settings = { facingMode: { exact: "environment" } };
        // const settings = { deviceId: { exact: cameraId } };
        const config = { fps: 10, qrbox: { width: 260, height: 260 } };
        this.html5QrCode!.start(
            settings,
            config,
            this.qrCodeSuccessCallback,
            this.qrCodeErrorCallback).catch((err) => {
                console.log(err)
            });
        this.setState({ cameraId });
        this.setState({ loadCamera: false });
    }

    public qrCodeSuccessCallback = async (decodedText: string, decodedResult: any) => {
        /* handle success */
        try {
            if (!isEmpty(decodedText) && this.state.qrCode !== decodedText) {

                const decodedRouter = decodeRouter(decodedText);
                const serialNumber = get(decodedRouter, 'serialNumber', '');

                this.setState({ qrCode: serialNumber, loadCamera: true });
                let response;

                if (!isEmpty(serialNumber)) {
                    const query = `{'7'.EX.'${serialNumber}'}`;
                    const data = {
                        "from": tid,
                        "select": [],
                        "where": query
                    }
                    // get data by qr code
                    const url = 'https://api.quickbase.com/v1/records/query';
                    response = await axios({
                        url,
                        method: 'POST',
                        headers,
                        data
                    });
                }


                const tableData = get(response, 'data.data[0]', undefined);
                const fields = get(response, 'data.fields');
                const fieldsToSelect = ['SSID', 'Serial number', 'MAC Address', 'Status', 'Record ID#'];
                const values = getFields(fields, tableData, fieldsToSelect);

                this.setState({
                    loadCamera: false,
                    defaultValues: {
                        id: get(values, fieldsToSelect[4], undefined),
                        macAddress: get(values, fieldsToSelect[2], get(decodedRouter, 'macAddress', '')),
                        ssid: get(values, fieldsToSelect[0], get(decodedRouter, 'ssid', '')),
                        serialNumber: get(values, fieldsToSelect[1], serialNumber),
                        status: {
                            value: get(values, fieldsToSelect[3], ''),
                            label: get(values, fieldsToSelect[3], '')
                        }
                    }
                });
                this.onStop();
            }
        } catch (error) {

            if (!isEmpty(decodedText)) {
                const decodedRouter = decodeRouter(decodedText);
                const serialNumber = get(decodedRouter, 'serialNumber', '');
                const ssid = get(decodedRouter, 'ssid', '');
                const macAddress = get(decodedRouter, 'macAddress', '');

                this.setState({
                    cameraId: '',
                    defaultValues: {
                        ...this.state.defaultValues,
                        serialNumber,
                        macAddress,
                        ssid
                    }
                });

                this.onStop();
            } else {
                if (redirect) {
                    window.location.href = redirect;
                }
            }
            console.error(error);
        }

    };

    public qrCodeErrorCallback = (err: any) => {
        // console.log(err);
    }

    public onStop = () => {
        try {
            this.setState({
                cameraId: '',
            })
            this.html5QrCode!.stop();
            this.html5QrCode!.clear();
        } catch (error) {
            console.log(error)
        }


    }

    public onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const {
                macAddress,
                serialNumber,
                ssid,
                status,
                id
            } = data;

            this.setState({ isLoading: true });
            const updatePayload = `
            <qdbapi>
                <apptoken>${QB_APP_TOKEN}</apptoken>
                ${id ? `<rid>${id}</rid>` : ``}
                <field name="mac_address">${macAddress}</field>
                <field name="ssid">${ssid}</field>
                <field name="status">${status.value}</field>
                <field name="serial_number">${serialNumber}</field>
            </qdbapi>
            `
            const xmlUrl = `${QB_HOSTNAME}/db/${tid}`
            const response = await axios({
                url: xmlUrl,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/xml',
                    "QUICKBASE-ACTION": id ? "API_EditRecord" : "API_AddRecord"
                },
                data: updatePayload
            });
            this.setState({ isLoading: false });
            if (redirect) {
                // window.location.href = fid_to_append && id ? `${redirect}&${fid_to_append}=${id}` : redirect;
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(response.data,"text/xml");
                const rid = xmlDoc.getElementsByTagName("rid")[0].childNodes[0].nodeValue;
                if(!isEmpty(fid_to_append) && rid){
                    search.redirect = undefined;
                    search.api = undefined;
                    search[fid_to_append] = rid;
                    const stringified = queryString.stringify(search);
                    window.location.href = `${redirect}&${stringified}`;
                }else{
                    window.location.href = redirect
                }
            }

        } catch (error) {
            this.setState({ isLoading: false });
            if (redirect) {
                window.location.href = redirect;
            }
        }

    };

    public onCancel = (e: React.SyntheticEvent) => {
        this.onStop();
        if (redirect) {
            window.location.href = redirect;
        }

    }

    public render(): React.ReactNode {
        if (isEmpty(this.state.defaultValues.serialNumber)) {
            return (
                <CameraContainer
                    loadCamera={this.state.loadCamera}
                    qrcodeRegionId={qrcodeRegionId}
                    onCancel={this.onCancel} />
            )

        } else {
            return (
                <RouterForm
                    readOnly={!!readOnly}
                    isLOading={this.state.isLoading}
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    defaultValues={this.state.defaultValues} />
            )
        }
    }
};

export default RouterScanner;
